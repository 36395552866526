<template>
    <div>

		<h2 v-if="isEditing">{{ $t('tiers.modifier_facture_fournisseur') }}</h2>
		<h2 v-else>{{ $t('tiers.ajouter_facture_fournisseur') }}</h2>

		<div class="col-12">
			<!-- choix de l'entité de facturation -->
			<div class="form-group">
				<label for="tiers_entity" class="col-form-label">{{ $t("tiers.choix_entite_facturee") }}</label>

				<e-select
					id="tiers_entity"
					track-by="tiers_id"
					label="tiers_rs"
					:options="tiers_entity"
					:loading="fetching_tiers"
					:searchable="true"
					:allow-empty="false"
					:show-labels="false"
					:placeholder="$t('tiers.choisir_entite_facturee')"
					v-model="invoice_supplier.tiers_entity"
				/>
			</div>

			<!-- copie de la facture -->
			<div class="form-group">
				<label for="file-picker" class="col-form-label">{{ $t("fichier.add_file") }}</label>

				<b-form-file
					id="file-picker"
					v-model="invoice_supplier.invoice_document"
					:state="Boolean(!!invoice_previous_docname || invoice_supplier.invoice_document)"
					:placeholder="invoice_previous_docname || $t('fichier.drop_or_select')"
					:drop-placeholder="$t('fichier.drop_file')"
				>
				</b-form-file>
			</div>

			<!-- numéro facture et nom fournisseur -->
			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label for="num-fact" class="col-form-label">{{ $t("tiers.num_facture_fournisseur") }}</label>
						<b-form-input id="num-fact" v-model="invoice_supplier.invoice_num"></b-form-input>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label for="supplier-name" class="col-form-label">{{ $t("tiers.nom_fournisseur") }}</label>
						<b-form-input id="supplier-name" v-model="invoice_supplier.supplier"></b-form-input>
					</div>
				</div>
			</div>

			<!-- date de la facture -->
			<div class="form-group">
				<label class="col-form-label">{{ $t("tiers.date_facture") }}</label>
				<e-datepicker v-model="invoice_supplier.invoice_date"></e-datepicker>
			</div>

			<!-- montant HT et TTC -->
			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label for="invoice-ht" class="col-form-label">{{ $t("invoice.montant_ht") }}</label>
						<b-form-input id="invoice-ht" v-model="invoice_supplier.invoice_ht"></b-form-input>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label for="invoice-ttc" class="col-form-label">{{ $t("invoice.montant_ttc") }}</label>
						<b-form-input id="invoice-ttc" v-model="invoice_supplier.invoice_ttc"></b-form-input>
					</div>
				</div>
			</div>

			<!-- Totaux TVA -->
			<div class="row">
				<div class="col-md-6" v-for="vat in invoice_supplier.totaux_tva" :key="vat.vat_id">
					<div class="form-group">
						<label for="invoice-ht" class="col-form-label">
							{{ $t("tiers.total_tva") }} {{ vat.vat_label }}
							<small @click="rmVat(vat.vat_id)"><font-awesome-icon :icon="['fal', 'times-square']" /></small>
						</label>
						<b-form-input id="invoice-vat" v-model="vat.amount"></b-form-input>
					</div>
				</div>
			</div>

			<!-- Ajout d'un taux de TVA -->
			<div class="form-group">
				<a v-if="!is_choosing_new_vat" href="#" @click.prevent="addTotalTva">{{ $t('tiers.ajouter_autre_total_tva') }}</a>
				<e-select
					v-else
					id="vat-choice"
					track-by="vat_id"
					label="vat_label"
					:options="unchosenVats"
					:loading="fetching_vats"
					:searchable="true"
					:allow-empty="false"
					:show-labels="false"
					:placeholder="$t('tiers.choix_tva')"
					@select="addChosenVat"
				/>
			</div>
		</div>

		<!-- Boutons d'actions -->
		<div class="text-center mt-5" v-if="has_submit == false">
			<button @click="performSave" class="btn btn-primary rounded-pill" :disabled="!isValidForm">
				<span v-if="invoice_id">{{ $t('global.modifier') }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'edit']" class="ml-1"/></span>
				<span v-else>{{ $t('global.ajouter') }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'plus-circle']" class="ml-1"/></span>
			</button>
			<b-button @click="cancel" class="btn btn-primary rounded-pill ml-1">
				{{ $t('global.annuler') }} <font-awesome-icon :icon="['fal', 'times']" class="ml-1"/> 
			</b-button>
		</div>

    </div>
</template>


<script type="text/javascript">
	import Common from '@/assets/js/common'
	import Invoice from "@/mixins/Invoice.js"
	import Shutter from "@/mixins/Shutter.js"
	import Tiers from "@/mixins/Tiers.js"

	export default {
		name: "InvoiceSupplierAdd",
		mixins: [Invoice, Shutter, Tiers],
		props: {
			tiers_id: Number,
			invoice_id: { type: Number, default: null }
		},
		data () {
			return {
				tiers_entity: [],
				fetching_tiers: false,

				vats: [],
				fetching_vats: false,
				has_submit: false,

				processing: false,
				is_choosing_new_vat: false,

				invoice_supplier: {
					tiers_entity: null,
					invoice_document: null,
					invoice_num: '',
					supplier: '',
					invoice_date: new Date(),
					invoice_ht: '',
					invoice_ttc: '',
					totaux_tva: []
				},

				invoice_previous_docname: '',
				unrequied: [
					'invoice_document',
					'totaux_tva'
				]
			}
		},
		computed: {
			isEditing() {
				return !!this.invoice_id && this.invoice_id > 0
			},
			unchosenVats() {
				return this.vats.filter(vat => {
					return !this.invoice_supplier.totaux_tva.find(v => v.vat_id === vat.vat_id)
				})
			},
			isValidForm() {
				const keys = Object.keys(this.invoice_supplier)
					.filter(key => this.unrequied.indexOf(key) === -1)

				let unvalid = []

				keys.forEach(key => {
					if(Array.isArray(this.invoice_supplier[key])) {
						if(this.invoice_supplier[key].length === 0) unvalid.push(key)
					}
					else {
						if(!this.invoice_supplier[key]) unvalid.push(key)
					}
				})

				if(!parseFloat(this.invoice_supplier.invoice_ht) || !parseFloat(this.invoice_supplier.invoice_ttc)) {
					return false
				}

				return unvalid.length === 0
			}
		},
		created() {
			this.preinit_component()
		},
		methods: {
			preinit_component() {
				this.fetching_tiers = true
				this.fetching_vats  = true
				
				this.loadTier(this.tiers_id)
				.then(result => {
					this.tiers_entity.push(result)
				})
				.finally(() => {
					this.fetching_tiers = false
					this.invoice_supplier.tiers_entity = this.tiers_entity[0]
				})

				this.getVat()
				.then(res => {
					this.vats = res

					if(!this.isEditing) {
						const vat = res.find(v => v.vat_value == 0.1)
						this.invoice_supplier.totaux_tva.push(vat)
					}
				})
				.finally(() => {
					this.fetching_vats = false
				})

				if(this.isEditing) {
					Common.waitUntil(() => !(this.fetching_tiers && this.fetching_vats))
						.then(() => {
							this.loadInvoiceSupplier(this.invoice_id)
						})
				}
			},
			async loadInvoiceSupplier(invoice_id) {
				const invoicesupplier = await this.getInvoiceSupplier(invoice_id)

				this.invoice_supplier.invoice_num = invoicesupplier.invoice_num,
				this.invoice_supplier.supplier = invoicesupplier.supplier,
				this.invoice_supplier.invoice_date = new Date(invoicesupplier.invoice_date),
				this.invoice_supplier.invoice_ht = invoicesupplier.invoice_ht,
				this.invoice_supplier.invoice_ttc = invoicesupplier.invoice_ttc
				this.invoice_previous_docname = invoicesupplier.invoice_docname

				if(!invoicesupplier.vats || invoicesupplier.vats.length === 0) {
					const vat = this.vats.find(v => v.vat_value == 0.1)
					this.invoice_supplier.totaux_tva.push(vat)
				}
				else {
					invoicesupplier.vats.forEach(vat => {
						this.invoice_supplier.totaux_tva.push({
							...vat.invoicesuppliersvat_vat,
							amount: vat.invoicesuppliersvat_total
						})
					})
				}

				this.invoice_supplier.tiers_entity = this.tiers_entity[0]
			},
			addTotalTva() {
				this.is_choosing_new_vat = true
			},
			addChosenVat(selectedVat) {
				this.invoice_supplier.totaux_tva.push(selectedVat)
				this.is_choosing_new_vat = false
			},
			rmVat(vatId) {
				const index = this.invoice_supplier.totaux_tva.findIndex(vat => vat.vat_id === vatId)
				if(index > -1) this.invoice_supplier.totaux_tva.splice(index, 1)
			},
			async performSave() {
				this.processing = true

				let ht = this.invoice_supplier.invoice_ht.toString().replace(",", ".")
				ht = ht.replace(" ", "")

				let ttc = this.invoice_supplier.invoice_ttc.toString().replace(",", ".")
				ttc = ttc.replace(" ", "")

				let params = {
					'tier': this.invoice_supplier.tiers_entity.tiers_id,
					'num_invoice': this.invoice_supplier.invoice_num,
					'supplier': this.invoice_supplier.supplier,
					'date': this.invoice_supplier.invoice_date,
					'amount_ht': parseFloat(ht) * 100,
					'amount_ttc': parseFloat(ttc) * 100,
					'vat': []
				}

				const vats = this.invoice_supplier.totaux_tva

				if(vats.length > 0) {
					vats.forEach(vat => {
						params.vat.push({
							vat_id: vat.vat_id,
							amount: parseFloat(vat.amount.toString().replace(" ", "").replace(",", ".")) * 100
						})
					})
				}

				// On insère la facture fournisseur
				let inserted = null
				this.saveInvoiceSupplier(params, this.invoice_id)
					.then(res => {
						inserted = res

						const inserted_id  = res.invoice_id

						// On joint le document
						if(this.invoice_supplier.invoice_document) {
							return this.uploadInvoiceDocument(inserted.invoice_id, this.invoice_supplier.invoice_document)
						}
						return
					})
					.then(() => {
						// On envoie le hook de validation du form
						this.ok({invoice: inserted})
					})
					.catch(e => {
					})
					.finally(() => {
						this.processing = false
						this.has_submit = true
					})
			}
		}
	}

</script>